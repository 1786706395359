import defaultSmallUserImage from 'images/default/small/user.jpg'

siteNavigationApp.controller('siteNavigationCtrl', ['$scope', '$location', 'DebugHelper', 'localStorageService', 'ExternalLinkHelper', 'SiteNavigationService', 'myPartnersService', 'UserManager', '$filter', 'reactNavigationService', '$window', 'WindowHelper', ($scope, $location, DebugHelper, localStorageService, ExternalLinkHelper, SiteNavigationService, myPartnersService, UserManager, $filter, reactNavigationService, $window, WindowHelper) ->
  DebugHelper.register("siteNavigationCtrl", $scope)

  _.assignIn $scope, {
    returnPath: Packs.iKnowRoutes.v3_dashboard_path()
    myPartnersService: myPartnersService
    routes: Packs.iKnowRoutes
    SiteNavigationService: SiteNavigationService
    ExternalLinkHelper: ExternalLinkHelper
    uiState:
      shouldShowMobileNav: false
    defaultPartnerImage: defaultSmallUserImage

    selectProduct: (product) ->
      if product
        SiteNavigationService.setProduct(product)
        $scope.product = product

    # this should be replaced with a breadcrumb type of thing when we have multiple nesting
    setCurrentSubNav: (tab) ->
      $scope.currentSubNav = tab

    selectNav: (nav) ->
      $scope.currentNav = nav

    setUIState: () ->
      $scope.returnPath = Packs.iKnowRoutes.v3_dashboard_path()

      $scope.uiState =
        shouldShowMobileNav: false
      unless SiteNavigationService.context_type
        if $scope.product == 'create'
          $scope.uiState.showContentInspiration = true
        $scope.uiState.showHelpCenter = true
      $scope.setReferralLink()

      switch $scope.product
        when "static"
          $scope.uiState.showHelpCenter = false
          $scope.uiState.showContentInspiration = false
          if window.location.href.match("cards")
            $scope.uiState.staticWithContext = true
            $scope.uiState.context = "cards"
          else if window.location.href.match("tis")
            $scope.uiState.staticWithContext = true
            $scope.uiState.context = "tis"
          else
            $scope.uiState.staticWithoutContext = true
        when "cerego_admin"
          $scope.uiState.ceregoAdmin = true
        when "courses"
          if myPartnersService.currentProductPartner?.relationships?.account?.data
            $scope.uiState.coursesWithPlusPartner = true

          if !SiteNavigationService.context_type
            $scope.uiState.coursesWithoutContext = true
          else if SiteNavigationService.context_type && !!$scope.currentSubNav
            $scope.uiState.coursesWithContextAndSubNav = true

          if SiteNavigationService.context_type == "course" && SiteNavigationService.subcontext_type == "learner"
            $scope.uiState.courseWithPartnerUser = true
          else if SiteNavigationService.context_type == 'course'
            $scope.uiState.coursesWithCourseContext = true

        when "groups"
          if SiteNavigationService.context_type == "group" && SiteNavigationService.subcontext_type == "member"
            $scope.uiState.proAdminWithGroupMember = true
          else
            $scope.uiState.groups = true

        when "create"
          if !SiteNavigationService.context_type
            $scope.uiState.createWithoutContext = true
          else
            $scope.uiState.createWithContext = true

          if SiteNavigationService.context_type == "set"
            $scope.uiState.createWithSetContext = true
          else if SiteNavigationService.context_type == "series"
            $scope.uiState.createWithSeriesContext = true

          $scope.setCreateVisibleTabs() if SiteNavigationService.context

        when "learn"
          if SiteNavigationService.subcontext_type == "instructional_content"
            if SiteNavigationService.context_type && $scope.currentSubNav
              $scope.uiState.learnShowBackInstructional = true
              $scope.returnPath = $scope.setInstructionalReturnPath()
              break
            else
              break
          else if SiteNavigationService.context_type && $scope.currentSubNav
            $scope.uiState.learnShowBackHome = true
          else
            $scope.uiState.learnWithoutBothContextAndSubNav = true

          if SiteNavigationService.context_type == "set"
            $scope.uiState.learnWithSetContext = true
          else if SiteNavigationService.context_type == "series"
            $scope.uiState.learnWithSeriesContext = true
          else if SiteNavigationService.context_type == "course"
            $scope.uiState.learnWithCourseContext = true
          else if SiteNavigationService.context_type == "assignment"
            $scope.uiState.learnWithAssignmentContext = true

        when "pro_admin"
          if SiteNavigationService.context_type == "partner" && SiteNavigationService.subcontext_type == "people"
            $scope.uiState.proAdminWithPeople = true
          else if SiteNavigationService.context_type == "partner" && SiteNavigationService.subcontext_type == "dashboard"
            $scope.uiState.proAdminWithDashboard = true
          else if myPartnersService.currentProductPartner
            $scope.uiState.proAdminWithPartner = true

    setCreateVisibleTabs: ->
      new_version = SiteNavigationService.context.learn_version == 4
      $scope.uiState.create = {
        shouldShowItems: !SiteNavigationService.context.scorm_package_id
        shouldShowSeries: !SiteNavigationService.context.scorm_package_id && !new_version
        shouldShowEdit: SiteNavigationService.can_edit
        shouldShowDistractors: SiteNavigationService.can_edit && myPartnersService.isBBC()  && !new_version
        shouldShowCollab: SiteNavigationService.can_edit && !SiteNavigationService.context.partner_id
        shouldShowHistory: !SiteNavigationService.context.scorm_package_id && SiteNavigationService.can_edit
        shouldShowquickEntry: SiteNavigationService.can_edit && !SiteNavigationService.context.scorm_package_id && !new_version
        shouldShowBulkUpload: SiteNavigationService.can_edit && !SiteNavigationService.context.scorm_package_id && !new_version
        shouldShowCSV: SiteNavigationService.can_edit && !SiteNavigationService.context.scorm_package_id && !new_version
      }


    getCurrentPartner: ->
      if myPartnersService.currentProductPartner?.attributes["account-type"] == 'plus'
        $scope.buildNameString()

    toggleLeftNavMobile: () ->
      $scope.uiState.shouldShowMobileNav = !$scope.uiState.shouldShowMobileNav

    setCurrentPath: () ->
      $scope.currentPath = $location.path()

    setInstructionalReturnPath: () ->
      if SiteNavigationService.context?.type == "sets"
        Packs.iKnowRoutes.v3_learn_set_instructional_items_path(SiteNavigationService.context)
      else if SiteNavigationService.context?.type == "series"
        Packs.iKnowRoutes.v3_learn_series_instructional_items_path(SiteNavigationService.context)
      else if SiteNavigationService.context_type == "course"
        Packs.iKnowRoutes.v3_learn_course_instructional_items_path(SiteNavigationService.context)

    setReferralLink: () ->
      if myPartnersService.currentProductPartner && $scope.currentUser && !['K12','K-12'].includes(myPartnersService.currentProductPartner.attributes.vertical) && myPartnersService.currentProductPartner.attributes.segment == 'Education'
        if $scope.currentUser.id % 2 == 0
          $scope.uiState.showReferralLink = 'referralLinkA'
        else
          $scope.uiState.showReferralLink = 'referralLinkB'

    buildNameString: () ->
      if !myPartnersService.currentProductPartner?.attributes?.name
        $scope.nameString = $filter("translate", "js.top_nav.me")
      else
        $scope.nameString = $filter('nameInitialHtml')(myPartnersService.currentProductPartner.attributes.name)

    goToV4Nav: () ->
      if !($window.location.href.includes('navigationOverride=v3') || $window.location.href.includes('browser/upgrade')) # TODO: Remove first clause after full rollout
        $window.document.cookie = "use_v4_nav=true; max-age=#{ 60 * 60 * 24 * 365 }; path=/;secure;";
        $window.location.href = reactNavigationService.getBaseReactUrl() + '/app/nav/v4/dashboard'
  }

  $scope.$on "toggleLeftNavMobile", (event) ->
    $scope.toggleLeftNavMobile()

  $scope.$on '$routeChangeSuccess', ($event, current, previous) ->
    # TODO: Add logic for different products here
    SiteNavigationService.reset()
    $scope.selectProduct(current.locals.$product)
    $scope.selectNav(current.locals.$nav)
    $scope.setCurrentSubNav(current.locals.$tab)
    $scope.getCurrentPartner()
    $scope.setCurrentPath()
    $scope.setUIState()

  SiteNavigationService.$watch "context_type", (current, previous) ->
    $scope.setUIState()

  $scope.$watch 'myPartnersService.currentProductPartner', (current, previous) ->
    $scope.setUIState()

  myPartnersService.initialize().then () ->
    $scope.currentUser = UserManager.currentUser
    $scope.partnerLibraries = $scope.currentUser?.relationships?["visible-libraries"]?.data
    $scope.getCurrentPartner()

    forceV3Nav = $scope.product == 'cerego_admin' # Some site admin tools still use V3 nav, so don't redirect them to V4 nav
    forceV3Nav ||= $window.location.pathname.startsWith('/lti') # Don't do any redirecting if this is an LTI path
    currentPartner = myPartnersService.currentProPartner || $scope.currentUser?.relationships?['primary-partner']?.data
    if currentPartner?.attributes["partner-settings"]?['show-new-nav'] && !WindowHelper.inIframe() && !forceV3Nav
      UserManager.updatePrimaryPartner(currentPartner.id).then () ->
        $scope.goToV4Nav()


  $scope.$on "siteNav:updateUI", (event) ->
    $scope.setUIState()

  $scope
])
